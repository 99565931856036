import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from '../router/index'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    jwt: null,
    snackbarShow: false,
    snackbarMessage: '',
    selectedCollectionIds: [],
    selectedProductNames: ["Storage Cube",
      "45 Cabinet",
      "Stereocab",
      "Browser Cube"],
    selectedFinishNames: ["Walnut", "Clear Lacquer", "Unfinished", "Black"],
    workHours: '4'
  },
  getters: {},
  mutations: {
    setSnackbarMessage (state, payload) {
      state.snackbarMessage = payload
    },
    setSnackbarShow (state, payload) {
      state.snackbarShow = payload
    },
    setUser (state, payload) {
      state.user = payload.user
      state.jwt = payload.jwt
    },
    logout (state) {
      state.user = null
      state.jwt = null
      router.push('account')
    },
    setSelectedCollectionIds (state, payload) {
      state.selectedCollectionIds = payload
    },
    setSelectedProductNames (state, payload) {
      state.selectedProductNames = payload
    },
    setSelectedFinishNames (state, payload) {
      state.selectedFinishNames = payload
    },
    setWorkHours(state, payload){
      state.workHours = payload
    }
  },
  actions: {
    login ({ commit }, payload) {
      Vue.axios
        .post('auth/local', {
          identifier: payload.identifier,
          password: payload.password,
        })
        .then((res) => {
          commit('setUser', res.data)
          router.push('/')
        })
        .catch((err) => {
          commit('setSnackbarMessage', err.response.data.message)
          commit('setSnackbarShow', true)
          console.error(err)
        })
    },
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => {
          let expiry = new Date(Date.now())
          expiry.setTime(expiry.getTime() + 4 * 60 * 60 * 1000)
          return Cookies.set(key, value, {
            expires: expiry,
            secure: true,
            sameSite: 'Strict',
          })
        },
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
})
