import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false

// axios.defaults.baseURL =
//   process.env.NODE_ENV == 'development'
//     ? 'http://localhost:1338'
//     : 'https://strapi.simplewoodgoods.com/'

// if running dev frontend w production backend:
axios.defaults.baseURL ='https://strapi.simplewoodgoods.com/'

let token = ``
if (store.state.user) {
  token = `Bearer ${store.state.jwt}`
}
axios.defaults.headers.common['Authorization'] = token

Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
