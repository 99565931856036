import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
const Home = () => import('../views/Home')
const Account = () => import('../views/Account')
const Products = () => import('../views/Products.vue')
const Queue = () => import('../views/Queue.vue')
const Stats = () => import('../views/Stats.vue')
const Reports = () => import('../views/Reports.vue')
const Schedule = () => import('../views/Schedule.vue')
const WorkDays = () => import('../views/WorkDays.vue')


Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        open: true,
      },
    },
    {
      path: '/products',
      name: 'Products',
      component: Products
    },
    {
      path: '/queue',
      name: 'Queue',
      component: Queue
    },
    {
      path: '/stats',
      name: 'Stats',
      component: Stats
    },
    {
      path: '/reports',
      name: 'Reports',
      component: Reports
    },
    {
      path: '/schedule',
      name: 'Schedule',
      component: Schedule
    },
    {
      path: '/work-days',
      name: 'Work Days',
      component: WorkDays
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => !record.meta.open)) {
    try {
      if (store.state.user == null) {
        next({
          path: '/account',
          params: { nextUrl: to.fullPath },
        })
      } else {
        next()
      }
    } catch (error) {
      next({
        path: '/account',
        params: { nextUrl: to.fullPath },
      })
    }
  } else {
    next()
  }
})

export default router
