<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = true" />
      SWG Tools
      <v-spacer />
      <div v-if="user">
        Hi, {{ user.firstName }}
        <v-app-bar-nav-icon @click="logout()">
          <v-icon>
            logout
          </v-icon>
        </v-app-bar-nav-icon>
      </div>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>receipt</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Orders</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item to="queue">
          <v-list-item-icon>
            <v-icon>production_quantity_limits</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Build List</v-list-item-title>
        </v-list-item> -->
        <v-list-item to="products">
          <v-list-item-icon>
            <v-icon>warehouse</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Products</v-list-item-title>
        </v-list-item>
        <v-list-item to="stats">
          <v-list-item-icon>
            <v-icon>bar_chart</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Stats</v-list-item-title>
        </v-list-item>
        <v-list-item to="reports">
          <v-list-item-icon>
            <v-icon>query_stats</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Reports</v-list-item-title>
        </v-list-item>
        <v-list-item to="schedule">
          <v-list-item-icon>
            <v-icon>calendar_month</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Production Schedule</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  computed: {
    user() {
      return this.$store.state.user
    },
  },
  watch: {
    user(val) {
      let token = ''
      if (val != null) {
        token = `Bearer ${this.$store.state.jwt}`
      }
      this.axios.defaults.headers.common['Authorization'] = token
    },
  },
  data: () => ({
    drawer: false,
  }),
  methods: {
    logout() {
      this.$store.commit('logout')
    },
  },
}
</script>
